import Spinner from '@/components/Spinner/Spinner';
import PlantNavigationHeader from '@/components/StationNavigationHeader/StationNavigationHeader';
import StationDeviceController from './StationDevice.controller';

import styles from './StationDevice.module.scss';

const StationDevice = () => {
    const { stationDeviceData } = StationDeviceController();

    const goBack = () => {
        window.history.back();
    };

    return (
        <div>
            <PlantNavigationHeader showBack subsectionText="DESCRIPCIÓN" onBack={goBack} />
            <div className={styles.mainContainer}>
                <p className={styles.title}>Datos en tiempo real / {stationDeviceData?.name}</p>
                <div className={styles.table}>
                    <div className={styles.tableHeaderRow}>
                        <div className={styles.headerCell}>String</div>
                        <div className={styles.headerCell}>Voltaje de entrada (V)</div>
                        <div style={{ marginRight: 0 }} className={styles.headerCell}>
                            Corriente de entrada (A)
                        </div>
                    </div>
                    {stationDeviceData?.strings?.length &&
                    stationDeviceData?.strings?.length > 0 ? (
                        stationDeviceData?.strings.map((item, index) => (
                            <div
                                className={styles.tableBodyRow}
                                style={index % 2 === 0 ? {} : { backgroundColor: '#fefce7' }}
                            >
                                <div className={styles.bodyCell}>{item.name}</div>
                                <div className={styles.bodyCell}>{item.voltage}</div>
                                <div className={styles.bodyCell}>{item.current}</div>
                            </div>
                        ))
                    ) : (
                        <div className={styles.spinnerContainer}>
                            <Spinner isLoading />
                        </div>
                    )}
                </div>
                <p className={styles.title}>Más información</p>
                <div className={styles.moreInfoContainer}>
                    <div className={styles.moreInfoTable}>
                        <div
                            className={styles.moreInfoTableBodyRow}
                            style={{ backgroundColor: '#fefce7' }}
                        >
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Estado del inversor
                            </div>
                            <div className={styles.bodyCell}>
                                {stationDeviceData?.status === 'active'
                                    ? 'Conectado a la red'
                                    : 'Desconectado de la red'}
                            </div>
                        </div>
                        <div className={styles.moreInfoTableBodyRow}>
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Potencia activa
                            </div>
                            <div className={styles.bodyCell}>{stationDeviceData?.power}</div>
                        </div>
                        <div
                            className={styles.moreInfoTableBodyRow}
                            style={{ backgroundColor: '#fefce7' }}
                        >
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Factor de Potencia
                            </div>
                            <div className={styles.bodyCell}>{stationDeviceData?.power_factor}</div>
                        </div>
                    </div>

                    <div className={styles.moreInfoTable}>
                        <div
                            className={styles.moreInfoTableBodyRow}
                            style={{ backgroundColor: '#fefce7' }}
                        >
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Producido hoy
                            </div>
                            <div className={styles.bodyCell}>
                                {stationDeviceData?.today_accumulated_energy}
                            </div>
                        </div>
                        <div className={styles.moreInfoTableBodyRow}>
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Potencia reactiva
                            </div>
                            <div className={styles.bodyCell}>
                                {stationDeviceData?.reactive_power}
                            </div>
                        </div>
                        <div
                            className={styles.moreInfoTableBodyRow}
                            style={{ backgroundColor: '#fefce7' }}
                        >
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Frecuencia de la red
                            </div>
                            <div className={styles.bodyCell}>
                                {stationDeviceData?.network_frequency}
                            </div>
                        </div>
                    </div>
                    <div className={styles.moreInfoTable}>
                        <div
                            className={styles.moreInfoTableBodyRow}
                            style={{ backgroundColor: '#fefce7' }}
                        >
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Producción total
                            </div>
                            <div className={styles.bodyCell}>
                                {stationDeviceData?.total_accumulated_energy}
                            </div>
                        </div>
                        <div className={styles.moreInfoTableBodyRow}>
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Potencia nominal del inversor
                            </div>
                            <div className={styles.bodyCell}>{stationDeviceData?.rated_power}</div>
                        </div>
                        <div
                            className={styles.moreInfoTableBodyRow}
                            style={{ backgroundColor: '#fefce7' }}
                        >
                            <div style={{ fontWeight: 'bold' }} className={styles.bodyCell}>
                                Hora de arranque del inversor
                            </div>
                            <div className={styles.bodyCell}>{stationDeviceData?.start_time}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StationDevice;
