import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';

import { getStationAlarmsByIdThunk, setSelectedStationId, updateStationAlarmThunk } from '@/store/stations/stations.slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function StationAlarmsController() {
    const dispatch = useAppDispatch()

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const stationAlarms = useSelector((state: RootState) => state.stations.stationAlarms);
    const stationAlarmsHistory = useSelector((state: RootState) => state.stations.stationAlarmsHistory);

    const setSelectedStationAction = (selectedStationId?: string) => dispatch(setSelectedStationId(selectedStationId))

    const fetchStationAlarms = async (id: string) => {
        setSelectedStationAction(id)
        if (id) {
            dispatch(getStationAlarmsByIdThunk({ station_id: id }))
        }
    }
    

    const { stationId } = useParams()

    useEffect(() => {
        if (stationId) {
            fetchStationAlarms(stationId)
        }
    }, [stationId])

    const onChangedAlarmEnabled = async (alarmId: string, enabled: number) => {

        if (stationId) {            
            await dispatch(updateStationAlarmThunk({ station_id: stationId, alarm_id: alarmId, alarm: { enabled: enabled ? 0 : 1 } }))
            dispatch(getStationAlarmsByIdThunk({ station_id: stationId }))
        }
    }



    return { stationId, fetchStationAlarms, setSelectedStationAction, selectedStationId, stationAlarms, stationAlarmsHistory, onChangedAlarmEnabled };
}

export default StationAlarmsController;
