import Input from '../Inputs/Input/Input';
import styles from './LoginComponent.module.scss';
import EmailIcon from '@/assets/icons/email-icon.svg';
import LockIcon from '@/assets/icons/lock-icon.svg';
import LoginComponentController from './LoginComponent.controller';
import Button from '../Button/Button';
import { ButtonSizes, ButtonVariants } from '../Button/Button.types';
import InputPassword from '../Inputs/InputPassword/InputPassword';
const LoginComponent = () => {
    const { email, setEmail, password, setPassword, isLoading, login, error, handleKeyPress } =
        LoginComponentController();

    return (
        <div className={styles.loginContainer}>
            <span className={styles.wave} />
            <div className={styles.content}>
                <div className={styles.upperLine}>
                    <div className={styles.upperLine__corner} />
                </div>
                <div className={styles.contentLogin}>
                    <div className={styles.title}>
                        ÁREA DE&nbsp;<b>ACCESO</b>
                    </div>
                    <div className={styles.line} />
                    <div className={styles.inputContainer}>
                        <Input
                            placeholder="Email"
                            IconLeft={EmailIcon}
                            value={email}
                            onChange={setEmail}
                            onKeyPress={handleKeyPress}
                        />
                        <InputPassword
                            placeholder="Contraseña"
                            IconLeft={LockIcon}
                            value={password}
                            onChange={setPassword}
                            onKeyPress={handleKeyPress}
                        />
                    </div>

                    {error && <p className={styles.error}>{error}</p>}

                    <div className={styles.containerButton}>
                        <div className={styles.button}>
                            <Button
                                onClick={() => {
                                    login();
                                }}
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.TERCIARY}
                            >
                                INICIAR SESIÓN
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={styles.lowerLine}>
                    <div className={styles.lowerLine__corner} />
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;
