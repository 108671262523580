import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const PricingType = {
    fixed: 'Importe fijo',
    pool: 'Cliente a pool'
};
export interface UserStation {
    id: string;
    client: string;
    contractedRateByClient: { value: string; label: string };
    contractedRate: { value: string; label: string };
    PS: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
}
export interface getUserStationsState {
    isLoading: boolean;
    error: Error | null;
    userStations: UserStation[] | null;
}

export const getUserStationsThunk = createAsyncThunk<
    UserStation[],
    { id: string },
    { rejectValue: Error }
>('userStations/getUserStations', async (params, thunkApi) => {
    return request({
        url: `/api/users/${params.id}/stations`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<UserStation[]>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getUserStationsSlice = createSlice({
    name: 'userStations',
    initialState: { isLoading: false, error: null, userStations: [] } as getUserStationsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserStationsThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = null), (state.userStations = payload);
        });
    }
});
