import { useField } from 'formik';
import styles from './InputDate.module.scss';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const start_date_of_the_times = new Date('1970-01-02');

interface InputDateProps {
    name: string;
    label: string;
    [x: string]: any;
}

const InputDate = ({ label, ...props }: InputDateProps) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    useEffect(() => {
        if (value) {
            const actual_date = new Date(value);

            if (actual_date < start_date_of_the_times) {
                toast.error('La fecha no puede ser anterior al 2 de enero de 1970');
                setValue('1970-01-02');
            }
        }
    }, [value]);

    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>{label}</label>
                <input
                    type={'date'}
                    className={styles.input}
                    value={value}
                    onChange={(text) => {
                        setValue(text.currentTarget.value);
                    }}
                />
            </div>
        </>
    );
};

export default InputDate;
