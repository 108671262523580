import styles from './InputSearch.module.scss';
import SearchIcon from '@/assets/icons/search.svg';
export interface InputSearchProps {
    value: string;
    setValue: (e: string) => void;
    onClickSearch: () => void;
}
const InputSearch = ({ value, setValue, onClickSearch }: InputSearchProps) => {
    return (
        <div className={styles.container}>
            <input
                className={styles.input}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
            />
            <SearchIcon className={styles.icon} onClick={onClickSearch} />
        </div>
    );
};

export default InputSearch;
