import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { loaderReducer } from './loader';
import { stationsReducer } from './stations';
import { UsersReducer } from './users';
import { UserStationsReducer } from './userStations';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    loader: loaderReducer,
    auth: authReducer,
    stations: stationsReducer,
    users: UsersReducer,
    userStations: UserStationsReducer
});

export default rootReducer;
