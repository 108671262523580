const get = () => {
    const userRole = localStorage.getItem('userRole');
    if (userRole) {
        return userRole;
    }
    return null;
};

const set = (userRole: string) => {
    localStorage.setItem('userRole', userRole);
};

const remove = () => {
    localStorage.removeItem('userRole');
};

const exists = () => {
    return !!get();
};

const userRole = {
    get,
    set,
    remove,
    exists
};

export default userRole;
