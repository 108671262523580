import { combineReducers } from 'redux';
import { getUserByIdSlice } from './getUserById.slice';
import { getUsersSlice } from './getUsers.slice';
import { postUserSlice } from './postUser.slice';

export const UsersReducer = combineReducers({
    getUsers: getUsersSlice.reducer,
    getUserById: getUserByIdSlice.reducer,
    postUser: postUserSlice.reducer
});

export const UsersActions = {
    ...getUsersSlice.actions,
    ...getUserByIdSlice.actions,
    ...postUserSlice.actions
};
