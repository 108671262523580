import EnergyMapController from './EnergyMap.controller';

import styles from './EnergyMap.module.scss';

const EnergyMap = () => {
    const { energy_map } = EnergyMapController();

    return (
        <div className={styles.stationDetailsItem}>
            <p className={styles.stationDetailsItemTitle}>Mapa de Energía</p>
            <img className={styles.image} src={energy_map} />
        </div>
    );
};

export default EnergyMap;
