import styles from './InputDate.module.scss';
interface InputDateProps {
    value: string;
    onChange: (string: any) => void;
    label: string;
}
const InputDate = ({ value, onChange, label }: InputDateProps) => {
    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>{label}</label>
                <input
                    type={'date'}
                    className={styles.input}
                    value={value}
                    onChange={(text) => onChange(text.currentTarget.value)}
                />
            </div>
        </>
    );
};

export default InputDate;
