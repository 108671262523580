import { useAppDispatch } from '@/index';
import { StationResume } from '@/models/StationResume';
import { RootState } from '@/store/rootReducer';
import { getStationResumeByIdThunk } from '@/store/stations/stations.slice';

import { useSelector } from 'react-redux';

const all_status: any = {
    thunderstorm: 'Tormenta',
    drizzle: 'Llovizna',
    rain: 'Lluvia',
    snow: 'Nieve',
    mist: 'Niebla',
    clear: 'Despejado',
    cloudy: 'Nublado',
    unknown: 'Desconocido'
};

function StatusIndicatorController() {
    const dispatch = useAppDispatch();

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);

    const selStationResume: StationResume | undefined = useSelector(
        (state: RootState) => state.stations.selectedStationResume
    );
    const stationsResume: StationResume | undefined = useSelector(
        (state: RootState) => state.stations.stationsResume
    );

    // const selStationResume = StatusIndicatorMock;
    // const stationsResume = StatusIndicatorMock;

    const getStationResumeById = (id?: string) => {
        // if (selectedStationId !== id) {
        dispatch(getStationResumeByIdThunk({ station_id: id }));
        // }
    };
    const parseStatusText = (status: string) => {
        return all_status[status] ?? 'Desconocido';
    };

    const selectedStationResume: StationResume | undefined =
        selectedStationId === undefined ? stationsResume : selStationResume;
    return { getStationResumeById, parseStatusText, selectedStationResume, selectedStationId };
}

export default StatusIndicatorController;
