import { useNavigate } from 'react-router-dom';
import accessToken from '@/utils/helpers/accessToken.helper';

function ClientLayoutController() {
    const navigate = useNavigate();

    const onCloseSession = () => {
        accessToken.remove();
        navigate('/login', {
            replace: true
        });
    };

    return { onCloseSession };
}

export default ClientLayoutController;
