import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AdminLayout.module.scss';
import Logo_PLENOENERGIA from '../../assets/images/logo-pleno-energia.png';
import Logo_BYCONVERGY from '../../assets/images/logo-byconvergy.png';

interface AdminLayoutProps {
    children?: React.ReactNode;
}
const AdminLayout = ({ children }: AdminLayoutProps) => {
    return (
        <>
            <div className={styles.header}>
                <Link to="/">
                    <img className={styles.logo} src={Logo_BYCONVERGY} />
                </Link>
            </div>
            <div className={styles.container}>{children}</div>
        </>
    );
};

export default AdminLayout;
