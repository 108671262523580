import styles from './StatusIndicators.module.scss';
import StatusIndicatorController from './StatusIndicators.controller';
import { FunctionComponent, SVGAttributes, useEffect } from 'react';
import ThunderIcon from '@/assets/icons/thunder.svg';
import ThunderSunIcon from '@/assets/icons/thunder-sun.svg';
import BombillaIcon from '@/assets/icons/bombilla.svg';
import TreeIcon from '@/assets/icons/tree.svg';
import CO2Icon from '@/assets/icons/co2.svg';
import CarIcon from '@/assets/icons/car.svg';

import TimeIcon from '../TimeIcon/TimeIcon';
interface ItemRowProps {
    Icon?: FunctionComponent<SVGAttributes<SVGElement>>;
    status?: 'thunderstorm' | 'drizzle' | 'rain' | 'snow' | 'mist' | 'clear' | 'cloudy' | 'unknown';
    number: number | string;
    unitText: string;
    subText: string;
    note?: string;
}

const StatusIndicators = () => {
    const { selectedStationResume, parseStatusText, getStationResumeById, selectedStationId } =
        StatusIndicatorController();

    useEffect(() => {
        getStationResumeById(selectedStationId);
    }, [selectedStationId]);

    if (selectedStationResume === undefined) {
        return <div className={styles.container}></div>;
    }
    const {
        total_capacity,
        total_accumulated_energy,
        month_accumulated_energy,
        environmental,
        weather,
        economic_impact
    } = selectedStationResume;

    const ItemRow = ({ Icon, status, number, unitText, subText, note }: ItemRowProps) => {
        return (
            <div className={styles.itemRow}>
                {Icon && (
                    <div className={styles.iconContainer}>
                        <Icon className={styles.iconContainer} />
                    </div>
                )}
                {status && (
                    <div className={styles.iconContainer}>
                        <TimeIcon status={status} className={styles.iconContainer__icon} />
                    </div>
                )}
                <div className={styles.textColumn}>
                    <div className={styles.textColumn__unitText}>
                        <div className={styles['textColumn__unitText--number']}>{number}</div>
                        <div className={styles['textColumn__unitText--text']}>{unitText}</div>
                        {note && (
                            <div className={styles.containerNote}>
                                <div className={styles.note}>{note}</div>
                            </div>
                        )}
                    </div>
                    <div className={styles.textColumn__text}>{subText}</div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.columnContainer}>
                    <p className={styles.title}>Indicadores de planta</p>

                    <ItemRow
                        Icon={ThunderIcon}
                        number={total_capacity?.toLocaleString('de-DE')}
                        unitText={'kW'}
                        subText={'POTENCIA INSTALADA'}
                    />
                    <ItemRow
                        Icon={ThunderSunIcon}
                        number={total_accumulated_energy?.toLocaleString('de-DE')}
                        unitText={'kW'}
                        subText={'ENERGÍA TOTAL GENERADA'}
                    />
                    <ItemRow
                        Icon={BombillaIcon}
                        number={month_accumulated_energy?.toLocaleString('de-DE')}
                        unitText={'kW'}
                        subText={'GENERADOS DE PROMEDIO EL ÚLTIMO MES'}
                    />
                </div>
                <div className={styles.columnContainer}>
                    <p className={styles.title}>Impacto Medioambiental</p>{' '}
                    <ItemRow
                        Icon={TreeIcon}
                        number={environmental?.reduction_total_tree?.toLocaleString('de-DE') ?? 0}
                        unitText={'Árboles'}
                        subText={`EQUIVALE A ${
                            environmental?.reduction_total_tree?.toLocaleString('de-DE') ?? 0
                        } ÁRBOLES PLANTADOS`}
                    />
                    <ItemRow
                        Icon={CO2Icon}
                        number={environmental?.reduction_total_co2?.toLocaleString('de-DE') ?? 0}
                        unitText={'kg'}
                        subText={`DE CO2 EVITADO`}
                    />
                    <ItemRow
                        Icon={CarIcon}
                        number={environmental?.car_km_equivalent?.toLocaleString('de-DE') ?? 0}
                        unitText={'km'}
                        subText={`HAS EVITADO LA CONTAMINACIÓN DE ${
                            environmental?.car_km_equivalent.toLocaleString('de-DE') ?? 0
                        } km EN COCHE`}
                    />
                </div>
                {/* <div className={styles.columnContainer}>
                    <p className={styles.title}>Impacto Económico</p>
                    <ItemRow
                        number={economic_impact.total_generated.toLocaleString('de-DE')}
                        unitText={'€'}
                        subText={'GENERADOS'}
                    />
                </div> */}
                <div className={styles.columnContainer}>
                    <p className={styles.title}>Previsión climatológica</p>
                    <ItemRow
                        number={weather?.today?.performance?.toLocaleString('de-DE')}
                        unitText={'% de rendimiento estimado'}
                        subText={parseStatusText(weather?.today?.status ?? 'unknown')}
                        note={'HOY'}
                        status={weather?.today?.status ?? 'unknown'}
                    />
                    <ItemRow
                        status={weather?.tomorrow?.status ?? 'unknown'}
                        number={weather?.tomorrow?.performance?.toLocaleString('de-DE')}
                        unitText={'% de rendimiento estimado'}
                        subText={parseStatusText(weather?.tomorrow?.status ?? 'unknown')}
                        note={'MAÑANA'}
                    />
                    <ItemRow
                        status={weather?.after_tomorrow?.status ?? 'unknown'}
                        number={weather?.after_tomorrow?.performance?.toLocaleString('de-DE')}
                        unitText={'% de rendimiento estimado'}
                        subText={parseStatusText(weather?.after_tomorrow?.status ?? 'unknown')}
                        note={'PASADO MAÑANA'}
                    />
                </div>
            </div>
        </div>
    );
};

export default StatusIndicators;
