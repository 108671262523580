import classNames from 'classnames';
import PaginationController from './Pagination.controller';
import styles from './Pagination.module.scss';
export interface PaginationProps {
    currentPage: number;
    maxPages: number;
    handleChangePage: (i: number) => void;
}

const Pagination = ({ handleChangePage, maxPages, currentPage }: PaginationProps) => {
    const { numbers, maxPagePages, currentPagePages, handleChangePagePages } = PaginationController(
        { maxPages, handleChangePage }
    );

    return (
        <nav aria-label="Page navigation example" className={styles.container}>
            <ul className={styles.content}>
                <li className={styles.item}>
                    <button
                        className={styles.button}
                        aria-label="Previous"
                        onClick={() => handleChangePagePages(currentPagePages - 1)}
                        disabled={currentPagePages < 1}
                    >
                        {'<'}
                    </button>
                </li>
                <li className={styles.item}>
                    <button
                        className={styles.button}
                        aria-label="Previous"
                        onClick={() => {
                            const currentP = currentPagePages + 1;
                            const aux = currentP * 10;
                            if (currentPage > aux - 9) {
                                handleChangePage(parseInt(currentPage?.toString()) - 1);
                            } else {
                                handleChangePagePages(parseInt(currentPagePages?.toString()) - 1);
                            }
                        }}
                        disabled={currentPage <= 1}
                    >
                        &laquo;
                    </button>
                </li>
                {numbers.map((i: number, key: number) => {
                    return (
                        <li className={styles.item} key={key}>
                            <button
                                className={classNames(
                                    styles.button,
                                    currentPage === i && styles.button__active
                                )}
                                onClick={() => {
                                    const val = key + 1;
                                    if (currentPage.toString() === val.toString()) {
                                    } else {
                                        handleChangePage(i);
                                    }
                                }}
                            >
                                {i}
                            </button>
                        </li>
                    );
                })}
                <li className={styles.item}>
                    <button
                        className={styles.button}
                        aria-label="Next"
                        onClick={() => {
                            const aux = currentPagePages + 1;
                            if (currentPage < aux * 10) {
                                handleChangePage(parseInt(currentPage?.toString()) + 1);
                            } else {
                                handleChangePagePages(parseInt(currentPagePages?.toString()) + 1);
                            }
                        }}
                        disabled={currentPage >= maxPages}
                    >
                        &raquo;
                    </button>
                </li>
                <li className={styles.item}>
                    <button
                        className={styles.button}
                        aria-label="Next"
                        onClick={() =>
                            handleChangePagePages(parseInt(currentPagePages?.toString()) + 1)
                        }
                        disabled={currentPagePages >= maxPagePages}
                    >
                        {'>'}
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
