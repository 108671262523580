import styles from './EditStation.module.scss';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import { Form, Formik, FormikProps } from 'formik';
import InputText from '@/components/InputsFormik/InputText/InputText';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import InputDate from '@/components/InputsFormik/InputDate/InputDate';
import { MyModal } from '@/components/MyModal/MyModal';
import InputSelect from '@/components/InputsFormik/InputSelect/InputSelect';
import InputTextV2 from '@/components/InputsFormik/InputTextV2/InputTextV2';
import EditStationController from './EditStation.controller';
import PlantNavigationHeader from '@/components/StationNavigationHeader/StationNavigationHeader';

interface StationFormik {
    id?: string;
    client: string;
    pricing_type: { value: string; label: string };
    tariff: { value: string; label: string };
    start_time: string;
    tiers: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
}

const EditStation = () => {
    const {
        initialValues,
        handleOnSubmit,
        setOpenModal,
        openModal,
        handleSaveTariff,
        generateStructure,
        params
    } = EditStationController();
    return (
        <>
            <div className={styles.container}>
                <PlantNavigationHeader showBack subsectionText="Administración" />
                <div className={styles.content}>
                    {/* <HeaderAdmin
                        titleBold="Administración"
                        //@ts-ignore
                        title={`/ GESTIÓN DE USUARIOS/  ${params.id}`}
                    /> */}
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                    >
                        {(
                            formik: FormikProps<{
                                name: string;
                                //tariff: string;
                                address: string;
                                cost: number;
                                start_time: string;
                                latitude: number;
                                longitude: number;
                            }>
                        ) => (
                            <>
                                <Form className={styles.formContainer}>
                                    <div className={styles.formContent}>
                                        <div className={styles.formContent__firstGrid}>
                                            <InputText
                                                label={'Nombre de la instalación'}
                                                name="name"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />
                                            <InputText
                                                label={'Coste (€)'}
                                                name="cost"
                                                placeholder="..."
                                                handleDisabled={true}
                                                type={'number'}
                                            />

                                            <InputDate name="start_time" label="Fecha de inicio:" />

                                            <InputText
                                                label={'Dirección'}
                                                name="address"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />
                                            <InputText
                                                label={'Latitud'}
                                                name="latitude"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />
                                            <InputText
                                                label={'Longitud'}
                                                name="longitude"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />

                                            {/* <div
                                                style={{
                                                    width: '200px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '10px'
                                                }}
                                            >
                                                <label htmlFor="" style={{ fontWeight: 'bold' }}>
                                                    Tarifa
                                                </label>
                                                <Button
                                                    size={ButtonSizes.ADAPTABLE}
                                                    variant={ButtonVariants.PRIMARY_OUTLINE}
                                                    onClick={() => setOpenModal(true)}
                                                >
                                                    Editar datos de tarifa
                                                </Button>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className={styles.button}>
                                        <Button
                                            type={'submit'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY}
                                        >
                                            Guardar Cambios
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
            {/* <MyModal
                handleClose={() => {
                    setOpenModal(false);
                }}
                title={`EDITAR INSTALACIÓN `}
                isOpen={openModal}
            >
                <div className={styles.containerModal}>
                    <Formik
                        enableReinitialize
                        initialValues={generateStructure()}
                        // validationSchema={StationByUserSchema}
                        onSubmit={handleSaveTariff}
                    >
                        {(formik: FormikProps<StationFormik>) => (
                            <>
                                <Form className={styles.formContainer}>
                                    <div className={styles.formContent}>
                                        <InputSelect
                                            label={'Modalidad de tarifa contratada por el cliente'}
                                            name="pricing_type"
                                            options={[
                                                { value: 'fixed', label: 'Importes fijo ' },
                                                { value: 'pool', label: 'Cliente a pool' }
                                            ]}
                                        />
                                        <InputSelect
                                            label={'Tarifa contratada por el cliente'}
                                            name="tariff"
                                            options={[
                                                { value: '2.0TD', label: '2.0TD' },
                                                { value: '3.0TD', label: '3.0TD' },
                                                { value: '6.1TD', label: '6.1TD' }
                                            ]}
                                        />

                                        <InputDate name="start_time" label="Fecha de inicio:" />

                                        <div className={styles.rates}>
                                            <div className={styles.rates__title}>
                                                Introducir valores de tarifas en € por (kw/h)
                                            </div>
                                            <div className={styles.rates__grid}>
                                                <InputTextV2
                                                    label={'P1:'}
                                                    label2={'€'}
                                                    name="tiers.P1"
                                                    placeholder=""
                                                    type="number"
                                                />
                                                <InputTextV2
                                                    label={'P2:'}
                                                    label2={'€'}
                                                    name="tiers.P2"
                                                    placeholder=""
                                                    type="number"
                                                />
                                                <InputTextV2
                                                    label={'P3:'}
                                                    label2={'€'}
                                                    name="tiers.P3"
                                                    placeholder=""
                                                    type="number"
                                                />
                                                {(formik.values.tariff.value === '3.0TD' ||
                                                    formik.values.tariff.value === '6.1TD') && (
                                                    <>
                                                        <InputTextV2
                                                            label={'P4:'}
                                                            label2={'€'}
                                                            name="tiers.P4"
                                                            placeholder=""
                                                            type="number"
                                                        />
                                                        <InputTextV2
                                                            label={'P5:'}
                                                            label2={'€'}
                                                            name="tiers.P5"
                                                            placeholder=""
                                                            type="number"
                                                        />
                                                        <InputTextV2
                                                            label={'P6:'}
                                                            label2={'€'}
                                                            name="tiers.P6"
                                                            placeholder=""
                                                            type="number"
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.button}>
                                        <Button
                                            type={'button'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY}
                                            onClick={() => handleSaveTariff(formik.values)}
                                        >
                                            Guardar Cambios
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </MyModal> */}
        </>
    );
};

export default EditStation;
