import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';

import { getStationDetailByIdThunk, setSelectedStationId } from '@/store/stations/stations.slice';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function StationDetailController() {
    const dispatch = useAppDispatch();
    const [size, setSize] = useState([0, 0]);

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));

    const fetchStationDetail = async (id: string) => {
        setSelectedStationAction(id);
        dispatch(getStationDetailByIdThunk({ station_id: id }));
    };

    const { stationId } = useParams();

    useEffect(() => {
        if (stationId) {
            fetchStationDetail(stationId);
        }
    }, [stationId]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return { fetchStationDetail, setSelectedStationAction, selectedStationId, size };
}

export default StationDetailController;
