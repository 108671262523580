import StatusIndicators from '@/components/StatusIndicators/StatusIndicators';
import { Link } from 'react-router-dom';
import Logo_PLENOENERGIA from '../../assets/images/logo-pleno-energia.png';
import Logo_BYCONVERGY from '../../assets/images/logo-byconvergy.png';
//import UserIcon from '@/assets/icons/user.svg';
import styles from './ClientLayout.module.scss';
import ClientLayoutController from './ClientLayout.controller';

interface ClientLayoutProps {
    children?: React.ReactNode;
}
const ClientLayout = ({ children }: ClientLayoutProps) => {
    const { onCloseSession } = ClientLayoutController();
    return (
        <>
            <div className={styles.header}>
                <Link to="/">
                    <img className={styles.logo} src={Logo_BYCONVERGY} />
                </Link>
                <div className={styles.userInfo}>
                    <div className={styles.userInfo__logout} onClick={() => onCloseSession()}>
                        CERRAR SESIÓN
                    </div>
                </div>
            </div>
            <StatusIndicators />
            <div className={styles.container}>{children}</div>
        </>
    );
};

export default ClientLayout;
