import { useField } from 'formik';
import styles from './InputText.module.scss';
import EditIcon from '@/assets/icons/pencil-square.svg';
import { useState } from 'react';
interface InputTextProps {
    label: string;
    name: string;
    type?: 'text' | 'number' | 'date' | 'password';
    placeholder?: string;
    handleDisabled?: boolean;
    [x: string]: any;
}
const InputText = ({ label, handleDisabled, ...props }: InputTextProps) => {
    const [field, meta] = useField(props.name);
    const [disabled, setDisabled] = useState<boolean>(handleDisabled === true ? true : false);

    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>
                    <div className={styles.label__text}>
                        {label}
                        {meta.touched && meta.error && <div style={{ color: 'red' }}>*</div>}
                    </div>
                    {handleDisabled && (
                        <EditIcon
                            className={styles.label__editIcon}
                            onClick={() => {
                                setDisabled(!disabled);
                            }}
                        />
                    )}
                </label>

                <input {...field} {...props} className={styles.input} disabled={disabled} />
            </div>
        </>
    );
};

export default InputText;
