import { useEffect, useState } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import Table from '@/components/HomeTable/HomeTable';
import { Link } from 'react-router-dom';
import HomeController from './Home.controller';

import styles from './Home.module.scss';
import CheckBox from '@/components/Checkbox/Checkbox';
import { StationList } from '@/models/StationList';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import InputDate from '@/components/Inputs/InputDate/InputDate';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { toast } from 'react-toastify';
import accessToken from '@/utils/helpers/accessToken.helper';
import { saveAs } from 'file-saver';
import Pagination from '@/components/Pagination/Pagination';
import PlantIcon from '@/assets/icons/plant.svg';
import TowerIcon from '@/assets/icons/tower.svg';
import { generateFilter } from '@/utils/helpers/filter.helper';
import { loaderActions } from '@/store/loader';
import InputSearch from '@/components/Inputs/InputSearch/InputSearch';

const Home = () => {
    const {
        setSelectedStationAction,
        stations,
        current_page,
        max_pages,
        selectedStationId,
        handleChangePage,
        dispatch,
        searchValue,
        setSearchValue,
        handleSearchText
    } = HomeController();

    const onSelectedRow = (newSelectedStationId?: string) => {
        if (newSelectedStationId === selectedStationId) {
            setSelectedStationAction(undefined);
        } else {
            setSelectedStationAction(newSelectedStationId);
        }
    };

    const columns: ColumnDef<StationList>[] = [
        {
            id: 'selector',
            header: 'Planta',
            cell: (info) => (
                <div
                    onClick={() => onSelectedRow(info.row.original.id)}
                    className={styles.tableCell}
                >
                    <CheckBox checked={info.row.original.id === selectedStationId} />
                </div>
            )
        },
        {
            header: 'Estado',
            cell: (info) => (
                <div className={styles.tableCell}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <div className={styles.icon}>
                            <PlantIcon className={styles[`icon__${info.row.original.status}`]} />
                        </div>
                        <div className={styles.icon}>
                            <TowerIcon className={styles[`icon__${info.row.original.status}`]} />
                        </div>
                    </div>
                </div>
            )
        },

        {
            accessorKey: 'name',
            cell: (info) => (
                <Link
                    to={info.row.original.id}
                    onClick={(e) => onSelectedRow(info.row.original.id)}
                >
                    {info.getValue()}
                </Link>
            ),
            header: 'Nombre'
        },
        {
            accessorKey: 'total_capacity',
            cell: (info?: any) =>
                info.getValue()?.toLocaleString()
                    ? info.getValue()?.toLocaleString('de-DE') + ' kWp'
                    : '-',
            header: 'Capacidad Total  (kWp)'
        },
        {
            accessorKey: 'power',
            cell: (info?: any) =>
                info.getValue()?.toLocaleString()
                    ? info.getValue()?.toLocaleString('de-DE') + ' kW'
                    : '-',
            header: 'Potencia Actual  (kW)'
        },
        {
            accessorKey: 'month_use_power',
            cell: (info?: any) =>
                info.getValue()?.toLocaleString()
                    ? info.getValue()?.toLocaleString('de-DE') + ' kWh'
                    : '-',
            header: 'CONSUMO ÚLTIMO MES (kWh)'
        },
        {
            accessorKey: 'month_accumulated_energy',
            cell: (info?: any) =>
                info.getValue()?.toLocaleString()
                    ? info.getValue()?.toLocaleString('de-DE') + ' kWh'
                    : '-',
            header: 'PRODUCIDO PLACAS (kWh)'
        },
        {
            accessorKey: 'net_production',
            cell: (info?: any) =>
                info.getValue()?.toLocaleString()
                    ? info.getValue()?.toLocaleString('de-DE') + ' kWh'
                    : '-',
            header: 'PRODUCIDO RED (kWh)'
        }
    ];

    // const currentDate = () => {
    //     var date = new Date();
    //     let month: any = date.getMonth() + 1;
    //     if (month < 10) month = '0' + month;
    //     var current = date.getFullYear() + '-' + month + '-' + date.getDate();
    //     return current ? current : '';
    // };

    const generateSubHeader = () => {
        var firstDateTest = new Date();
        var secondDateTest = new Date();
        let day = firstDateTest.getDate();
        let startMonth = 0;
        let endMonth = 0;
        let firstYear = 0;
        let secondYear = 0;

        if (day >= 20 && day <= 31) {
            secondDateTest.setMonth(firstDateTest.getMonth() + 1);

            startMonth = firstDateTest.getMonth() + 1;
            endMonth = secondDateTest.getMonth() + 1;

            firstYear = firstDateTest.getFullYear();
            secondYear = secondDateTest.getFullYear();
        } else {
            firstDateTest.setMonth(secondDateTest.getMonth() - 1);

            startMonth = firstDateTest.getMonth() + 1;
            endMonth = secondDateTest.getMonth() + 1;

            firstYear = firstDateTest.getFullYear();
            secondYear = secondDateTest.getFullYear();
        }

        const subTitleHeaderTable = [
            '',
            '',
            '',
            '',
            '',
            `20/${startMonth}/${firstYear} - 19/${endMonth}/${secondYear}`,
            `20/${startMonth}/${firstYear} - 19/${endMonth}/${secondYear}`,
            `20/${startMonth}/${firstYear} - 19/${endMonth}/${secondYear}`
        ];
        return subTitleHeaderTable;
    };
    const [initialDate, setIntialDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const DownloadCSV = () => {
        dispatch(loaderActions.setLoadingTrue());
        request({
            url: `/api/users/stations/export`,
            method: 'GET',
            responseType: 'blob',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            },
            params: new URLSearchParams(
                generateFilter({
                    start_date: initialDate,
                    end_date: endDate,
                    station_id: selectedStationId ?? ''
                })
            )
        })
            .then((res: ApiResponse) => {
                const fileName = res.headers['content-disposition'].split('filename=')[1];

                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                saveAs(blob, fileName);
                dispatch(loaderActions.setLoadingFalse());
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error(err.status);
                dispatch(loaderActions.setLoadingFalse());
            });
    };

    // useEffect(() => {
    //     setIntialDate(currentDate());
    //     setEndDate(currentDate());
    // }, []);
    return (
        <div className={styles.container}>
            <div className={styles.downloadOptions}>
                <div className={styles.button}>
                    <Button
                        size={ButtonSizes.ADAPTABLE}
                        variant={ButtonVariants.PRIMARY}
                        onClick={DownloadCSV}
                    >
                        DESCARGAR RESUMEN CSV
                    </Button>
                </div>

                <div className={styles.dateSelect}>
                    <InputDate
                        value={initialDate}
                        onChange={(e) => {
                            setIntialDate(e);
                        }}
                        label="Fecha de inicio:"
                    />
                </div>
                <div className={styles.dateSelect}>
                    <InputDate
                        value={endDate}
                        onChange={(e) => {
                            setEndDate(e);
                        }}
                        label="Fecha de fin:"
                    />
                </div>
                <div className={styles.dateSelect}>
                    <InputSearch
                        value={searchValue}
                        setValue={setSearchValue}
                        onClickSearch={() => {
                            handleSearchText();
                        }}
                    />
                </div>
            </div>
            <Table
                selectedStationId={selectedStationId}
                data={stations ?? []}
                columns={columns}
                subTitleHeader={generateSubHeader()}
            />
            <Pagination
                handleChangePage={handleChangePage}
                currentPage={current_page}
                maxPages={max_pages}
            />
        </div>
    );
};

export default Home;
