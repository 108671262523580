
import { useEffect, useState } from 'react';
import styles from './TimeSelector.module.scss'


interface TimeSelectorProps {
    startTime?: string,
    onChangeStartTime: (value: string) => void,
    endTime?: string,
    onChangeEndTime: (value: string) => void,
}


const TimeSelector = ({
    startTime,
    onChangeStartTime,
    endTime,
    onChangeEndTime,
}: TimeSelectorProps) => {

    const [is24Hours, setIs24Hours] = useState(true);

    useEffect(() => {
        if(startTime && endTime) {
            setIs24Hours(false);
        }
    } , [startTime, endTime])

    return (
        <div className={styles.timeSelectorContainer}>
            <div
                className={styles.is24HoursContainer}
                onClick={() => setIs24Hours(!is24Hours)}
            >
                <div className={styles.label}>
                    24 h
                </div>
                <div className={is24Hours ? styles.activeDot : styles.inactiveDot} />
            </div>
            {!is24Hours && <>
                <div className={styles.label}>
                    Hora de inicio:
                </div>
                <input
                    className={styles.input}
                    type="time"
                    onChange={value => onChangeStartTime(value.target.value)}
                    value={startTime ?? undefined}
                />
                <div className={styles.label}>
                    Hora de fin:
                </div>
                <input
                    className={styles.input}
                    type="time"
                    onChange={value => onChangeEndTime(value.target.value)}
                    value={endTime ?? undefined}
                />
            </>}
        </div>
    )
}

export default TimeSelector;
