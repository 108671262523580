import { loginThunk } from './auth/login.slice';
import { getStationDetailByIdThunk, getStationsThunk } from './stations/stations.slice';
import { getUsersThunk } from './users/getUsers.slice';

const rootThunkData: any = [loginThunk, getUsersThunk, getStationsThunk, getStationDetailByIdThunk];

const rootThunkHandler = (data: any[]) => {
    const newJ = { data: {} };

    data.map((e) => {
        newJ.data = {
            ...newJ.data,
            [e.pending as string]: (state: { isLoading: boolean }) => {
                state.isLoading = true;
            },
            [e.fulfilled as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            },
            [e.rejected as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            }
        };
    });

    return newJ.data;
};

export const rootThunk = rootThunkHandler(rootThunkData);
