import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { StationList } from '@/models/StationList';
import { StationDetail } from '@/models/StationDetail';
import { StationResume } from '@/models/StationResume';
import { StationData } from '@/models/StationData';
import { StationAlarmsHistory } from '@/models/StationAlarmsHistory';
import { StationAlarm } from '@/models/StationAlarms';
import { TimeRangeOptions } from '@/models/TimeRangeOptions';
import { StationAlarmsCreateEdit } from '@/models/StationAlarmsCreateEdit';
import { StationDevice } from '@/models/StationDevice';
import { StationDeviceData } from '@/models/StationDeviceData';
import accessToken from '@/utils/helpers/accessToken.helper';
import { StationString } from '@/models/StationString';
import { DocumentType } from '@/models/DocumentTypes';
import { authActions } from '../auth';
import { StationResumeIS } from './InitialStates';
import { toast } from 'react-toastify';

export type UserFilter = {
    name?: string;
    client?: string;
    limit?: number | string;
    page?: number | string;
};
export interface GetStationsListState {
    isLoading: boolean;
    error: Error | null;
    stations: StationList[];
    current_page: number;
    max_pages: number;
}
export const getStationByIdThunk = createAsyncThunk<
    StationData,
    { station_id: string },
    { rejectValue: Error }
>('stations/getStationByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});
export const getStationByIdEditThunk = createAsyncThunk<
    StationData,
    { station_id: string },
    { rejectValue: Error }
>('stations/getStationByIdEditThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/edit`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const patchStationByIdEditThunk = createAsyncThunk<
    StationData,
    { station_id: string },
    { rejectValue: Error }
>('stations/patchStationByIdEditThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/edit`,
        method: 'PATCH',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const updateStationByIdThunk = createAsyncThunk<
    StationData,
    {
        station_id: string;
        station: StationData;
    },
    { rejectValue: Error }
>('stations/updateStationByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}`,
        method: 'PATCH',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        data: {
            ...param.station
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationData>) => {
            toast.success('Instalación actualizada con éxito');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error('La instalación no se ha podido actualizar');
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getStationsThunk = createAsyncThunk<
    GetStationsListState,
    UserFilter,
    { rejectValue: Error }
>('stations/getStations', async (params, thunkApi) => {
    return request({
        url: '/api/stations',
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        params: new URLSearchParams(params as any)
    } as ApiRequest)
        .then((res: ApiResponse<GetStationsListState>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getStationDetailByIdThunk = createAsyncThunk<
    StationDetail,
    {
        station_id: string;
        data_type?:
            | 'economic_performance'
            | 'production_vs_consumption'
            | 'wasted_energy'
            | 'investment_payback';
        time_range?: TimeRangeOptions;
    },
    { rejectValue: Error }
>('stations/getStationDetailByIdThunk', async (param, thunkApi) => {
    let url = `/api/stations/${param.station_id}/detail`;

    if (param.data_type && param.time_range) {
        url += `?data_type=${param.data_type}&time_range=${param.time_range}`;
    }

    return request({
        url,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationDetail>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getStationResumeByIdThunk = createAsyncThunk<
    StationResume,
    { station_id?: string },
    { rejectValue: Error }
>('stations/getStationResumeByIdThunk', async (param, thunkApi) => {
    const url =
        param.station_id !== undefined
            ? `/api/stations/${param.station_id}/resume`
            : '/api/stations/resume';

    return request({
        url,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationResume>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getStationDataByIdThunk = createAsyncThunk<
    StationData,
    { station_id: string },
    { rejectValue: Error }
>('stations/getStationDataByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/info`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

type StationAlarmsApiResponse = {
    alarms: StationAlarm[];
    alarms_history: StationAlarmsHistory[];
};

export const getStationAlarmsByIdThunk = createAsyncThunk<
    StationAlarmsApiResponse,
    { station_id: string },
    { rejectValue: Error }
>('stations/getStationAlarmsByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/alarms`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationAlarmsApiResponse>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getStationDevicesByIdThunk = createAsyncThunk<
    StationDevice[],
    { station_id: string },
    { rejectValue: Error }
>('stations/getStationDevicesByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/devices`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationDevice[]>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getStationDeviceByIdThunk = createAsyncThunk<
    StationDeviceData,
    {
        station_id: string;
        device_id: string;
    },
    { rejectValue: Error }
>('stations/getStationDeviceByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/devices/${param.device_id}`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationDeviceData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getStationStringsByIdThunk = createAsyncThunk<
    StationString[],
    { station_id: string },
    { rejectValue: Error }
>('stations/getStationStringsByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/strings`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationDevice[]>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const createStationAlarmThunk = createAsyncThunk<
    StationAlarmsApiResponse,
    {
        station_id: string;
        alarm: StationAlarmsCreateEdit;
    },
    { rejectValue: Error }
>('stations/createStationAlarmThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/alarms`,
        method: 'POST',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        data: {
            ...param.alarm
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationAlarmsApiResponse>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const updateStationAlarmThunk = createAsyncThunk<
    StationAlarm,
    {
        station_id: string;
        alarm_id: string;
        alarm: StationAlarmsCreateEdit;
    },
    { rejectValue: Error }
>('stations/updateStationAlarmThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/alarms/${param.alarm_id}`,
        method: 'PATCH',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        data: {
            ...param.alarm
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationAlarm>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

type StationDocumentApiResponse = {
    upload_type: string;
    logo?: string;
    contract?: string;
    receipt?: string;
    documents: DocumentDowloadedType[];
};

export const getStationDocumentByIdThunk = createAsyncThunk<
    StationDocumentApiResponse,
    {
        station_id: string;
        upload_type: 'logo' | 'contract' | 'receipt';
    },
    { rejectValue: Error }
>('stations/getStationDocumentByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/docs/${param.upload_type}`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationDocumentApiResponse>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

type DocumentDowloadedType = {
    name: string;
    url: string;
};

export const getStationDocumentsByIdThunk = createAsyncThunk<
    DocumentDowloadedType[],
    {
        station_id: string;
        upload_type: 'info' | 'certificates';
    },
    { rejectValue: Error }
>('stations/getStationDocumentsByIdThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/docs/${param.upload_type}`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<DocumentDowloadedType[]>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

type StationDocument = {
    id: string;
    name: string;
    description: string;
    type: string;
    size: number;
    created_at: string;
    updated_at: string;
    url: string;
};

export const uploadStationDocumentThunk = createAsyncThunk<
    StationDocument,
    {
        station_id: string;
        upload_type: DocumentType;
        document: FormData;
    },
    { rejectValue: Error }
>('stations/uploadStationDocumentThunk', async (param, thunkApi) => {
    return request({
        url: `/api/stations/${param.station_id}/docs/${param.upload_type}`,
        method: 'POST',
        data: param.document,
        header: {
            'Content-Type': 'multipart/form-data'
        },
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<StationDocument>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            if (err.status === 401) {
                accessToken.remove();
                thunkApi.dispatch(authActions.logout());
            }
            return thunkApi.rejectWithValue(err.data);
        });
});

const initialState: {
    selectedStationId?: string;
    stationList: {
        isLoading: false;
        error: null;
        current_page: number;
        max_pages: number;
        stations: StationList[];
    };
    stationDetail?: StationDetail;
    stationsResume?: StationResume;
    selectedStationResume?: StationResume;
    stationData?: StationData;
    stationAlarms?: StationAlarm[];
    stationAlarmsHistory?: StationAlarmsHistory[];
    stationDevices?: StationDevice[];
    stationDeviceData?: StationDeviceData;
    stationStrings?: StationString[];
    logo?: string;
    contract?: string;
    receipt?: string;
    info?: DocumentDowloadedType[];
    certificates?: DocumentDowloadedType[];
} = {
    selectedStationId: undefined,
    stationList: {
        isLoading: false,
        error: null,
        current_page: 0,
        max_pages: 0,
        stations: []
    },
    stationDetail: undefined,
    stationsResume: StationResumeIS,
    selectedStationResume: StationResumeIS,
    stationData: undefined,
    stationAlarms: undefined,
    stationAlarmsHistory: undefined,
    stationDevices: undefined,
    stationDeviceData: undefined,
    stationStrings: undefined,
    logo: undefined,
    info: undefined,
    contract: undefined,
    certificates: undefined,
    receipt: undefined
};

export const stationsSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {
        setSelectedStationId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedStationId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getStationsThunk.fulfilled, (state, { payload }) => {
            (state.stationList.isLoading = false),
                (state.stationList.error = null),
                (state.stationList.stations = payload.stations),
                (state.stationList.current_page = payload.current_page ? payload.current_page : 0),
                (state.stationList.max_pages = payload.max_pages ? payload.max_pages : 0);
        });
        builder.addCase(getStationDetailByIdThunk.pending, (state, action) => {
            if (state.stationDetail && action.meta.arg.data_type === 'economic_performance') {
                state.stationDetail = { ...state.stationDetail, economic_performance: undefined };
            } else if (
                state.stationDetail &&
                action.meta.arg.data_type === 'production_vs_consumption'
            ) {
                state.stationDetail = {
                    ...state.stationDetail,
                    production_vs_consumption: undefined
                };
            } else if (state.stationDetail && action.meta.arg.data_type === 'wasted_energy') {
                state.stationDetail = { ...state.stationDetail, wasted_energy: undefined };
            } else if (state.stationDetail && action.meta.arg.data_type === 'investment_payback') {
                state.stationDetail = { ...state.stationDetail, investment_payback: undefined };
            }
        });
        builder.addCase(getStationDetailByIdThunk.fulfilled, (state, action) => {
            if (action.meta.arg.data_type === undefined) {
                state.stationDetail = action.payload;
            } else if (
                state.stationDetail &&
                action.meta.arg.data_type === 'economic_performance'
            ) {
                state.stationDetail = {
                    ...state.stationDetail,
                    economic_performance: action.payload.economic_performance
                };
            } else if (
                state.stationDetail &&
                action.meta.arg.data_type === 'production_vs_consumption'
            ) {
                state.stationDetail = {
                    ...state.stationDetail,
                    production_vs_consumption: action.payload.production_vs_consumption
                };
            } else if (state.stationDetail && action.meta.arg.data_type === 'wasted_energy') {
                state.stationDetail = {
                    ...state.stationDetail,
                    wasted_energy: action.payload.wasted_energy
                };
            } else if (state.stationDetail && action.meta.arg.data_type === 'investment_payback') {
                state.stationDetail = {
                    ...state.stationDetail,
                    investment_payback: action.payload.investment_payback
                };
            }
        });
        builder.addCase(getStationDetailByIdThunk.rejected, (state, action) => {
            if (action.meta.arg.data_type === undefined) {
                state.stationDetail = undefined;
            } else if (
                state.stationDetail &&
                action.meta.arg.data_type === 'economic_performance'
            ) {
                state.stationDetail = {
                    ...state.stationDetail,
                    economic_performance: undefined
                };
            } else if (
                state.stationDetail &&
                action.meta.arg.data_type === 'production_vs_consumption'
            ) {
                state.stationDetail = {
                    ...state.stationDetail,
                    production_vs_consumption: undefined
                };
            } else if (state.stationDetail && action.meta.arg.data_type === 'wasted_energy') {
                state.stationDetail = {
                    ...state.stationDetail,
                    wasted_energy: undefined
                };
            } else if (state.stationDetail && action.meta.arg.data_type === 'investment_payback') {
                state.stationDetail = {
                    ...state.stationDetail,
                    investment_payback: undefined
                };
            }
        });
        builder.addCase(getStationResumeByIdThunk.fulfilled, (state, { meta, payload }) => {
            if (meta.arg.station_id === undefined) {
                state.stationsResume = payload;
            } else {
                state.selectedStationResume = payload;
            }
        });
        builder.addCase(getStationDataByIdThunk.fulfilled, (state, { payload }) => {
            state.stationData = payload;
        });
        builder.addCase(getStationDataByIdThunk.rejected, (state, { payload }) => {
            state.stationData = undefined;
        });
        builder.addCase(getStationAlarmsByIdThunk.fulfilled, (state, { payload }) => {
            state.stationAlarms = payload.alarms;
            state.stationAlarmsHistory = payload.alarms_history;
        });
        builder.addCase(getStationDevicesByIdThunk.fulfilled, (state, { payload }) => {
            state.stationDevices = payload;
        });
        builder.addCase(getStationDeviceByIdThunk.fulfilled, (state, { payload }) => {
            state.stationDeviceData = payload;
        });
        builder.addCase(getStationStringsByIdThunk.fulfilled, (state, { payload }) => {
            state.stationStrings = payload;
        });
        builder.addCase(getStationDocumentByIdThunk.fulfilled, (state, action) => {
            const { payload, meta } = action;
            switch (meta.arg.upload_type) {
                case 'logo':
                    state.logo = (payload as { logo: string }).logo;
                    break;
                case 'contract':
                    state.contract = (payload as { contract: string }).contract;
                    break;
                case 'receipt':
                    state.receipt = (payload as { receipt: string }).receipt;
                    break;
            }
        });
        builder.addCase(getStationDocumentsByIdThunk.fulfilled, (state, action) => {
            const { payload, meta } = action;
            switch (meta.arg.upload_type) {
                case 'info':
                    state.info = payload;
                    break;
                case 'certificates':
                    state.certificates = payload;
                    break;
            }
        });
    }
});

export const { setSelectedStationId } = stationsSlice.actions;
