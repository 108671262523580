import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface UserData {
    cif: string;
    email: string;
    maintenance_type: 'none' | 'silver' | 'gold';
    username: string;
    role: 'client' | 'technician' | 'admin';
}
export interface postUserState {
    isLoading: boolean;
    user: UserData | null;
}

export const postUserThunk = createAsyncThunk<UserData, {}, { rejectValue: Error }>(
    'users/postUser',
    async (params, thunkApi) => {
        return request({
            url: '/api/users',
            method: 'POST',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            },
            data: params
        })
            .then((res: ApiResponse<UserData>) => {
                return res.data;
            })
            .catch((err: ApiResponse<Error>) => {
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

export const postUserSlice = createSlice({
    name: 'postUser',
    initialState: { isLoading: false, user: null } as postUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postUserThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.user = payload);
        });
    }
});
