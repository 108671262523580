import { useAppDispatch } from '@/index';
import { DocumentType } from '@/models/DocumentTypes';
import { RootState } from '@/store/rootReducer';
import {
    getStationDocumentByIdThunk,
    getStationDocumentsByIdThunk,
    setSelectedStationId,
    uploadStationDocumentThunk
} from '@/store/stations/stations.slice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

type TabsType = DocumentType | undefined;

function StationDocumentationController() {
    const dispatch = useAppDispatch();
    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));
    const [selectedTab, setSelectedTab] = useState<TabsType>(undefined);
    const [showUploadFile, setShowUploadFile] = useState<boolean>(false);
    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const logo = useSelector((state: RootState) => state.stations.logo);
    const info = useSelector((state: RootState) => state.stations.info);
    const contract = useSelector((state: RootState) => state.stations.contract);
    const certificates = useSelector((state: RootState) => state.stations.certificates);
    const receipt = useSelector((state: RootState) => state.stations.receipt);

    const { stationId } = useParams();

    useEffect(() => {
        if (stationId) {
            setSelectedStationAction(stationId);
        }
    }, [stationId]);

    useEffect(() => {
        if (stationId && selectedTab) {
            if (selectedTab === 'info' || selectedTab === 'certificates') {
                dispatch(
                    getStationDocumentsByIdThunk({
                        station_id: stationId,
                        upload_type: selectedTab
                    })
                );
            } else {
                dispatch(
                    getStationDocumentByIdThunk({
                        station_id: stationId,
                        upload_type: selectedTab
                    })
                );
            }
        }
    }, [selectedTab]);

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file && selectedTab && selectedStationId) {
            let formData = new FormData();
            formData.append('file', file);
            setShowUploadFile(true);
            dispatch(
                uploadStationDocumentThunk({
                    station_id: selectedStationId,
                    upload_type: selectedTab,
                    document: formData
                })
            );
        }
    };

    return {
        selectedTab,
        setSelectedTab,
        showUploadFile,
        setShowUploadFile,
        handleFileInput,
        logo,
        info,
        contract,
        certificates,
        receipt
    };
}

export default StationDocumentationController;
