import styles from './DaySelector.module.scss'

interface DaySelectorProps {
    onChange: (value: string[]) => void;
    value?: string[];
}

const DAYS = ['l', 'm', 'x', 'j', 'v', 's', 'd']

const DaySelector = ({ onChange, value = [] }: DaySelectorProps) => {
    
    const onChangeDay = (day: string) => {
        if (value?.includes(day)) {
            onChange(value?.filter(item => item !== day))
        } else {
            onChange([...value, day])
        }
    
    }

    return (<div className={styles.daySelectorContainer}>
        {DAYS.map(day => (
            <div
                key={day}
                className={value?.includes(day) ? styles.daySelectedItem : styles.dayUnselectedItem}
                onClick={() => onChangeDay(day)}
            >
                {day.toUpperCase()}
            </div>

        ))}
    </div>)
}

export default DaySelector;

