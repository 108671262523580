import { useEffect, useState } from 'react';

const PaginationController = ({
    maxPages,
    handleChangePage
}: {
    maxPages: number;
    handleChangePage: (i: number) => void;
}) => {
    const [numbers, setNumbers] = useState<number[]>([]);
    const [currentPagePages, setCurrentPagePages] = useState(0);

    const [maxPagePages, setMaxPagePages] = useState<number>(0);

    const handleChangePagePages = (page: number) => {
        const firstPage = page * 10 + 1;
        setCurrentPagePages(page);

        handleChangePage(firstPage);
    };

    useEffect(() => {
        const maxPP = maxPages / 10;

        setMaxPagePages(maxPP - 1);
        const arrNumber = [];
        for (let i = currentPagePages; i < currentPagePages + 10; i++) {
            if (i < maxPages) arrNumber.push(i + 1);
        }
        setNumbers(arrNumber);
    }, [maxPages]);

    useEffect(() => {
        const arrNumber = [];
        const pages = currentPagePages * 10;
        for (let i = pages; i < pages + 10; i++) {
            if (i < maxPages) arrNumber.push(i + 1);
        }
        setNumbers(arrNumber);
    }, [currentPagePages]);

    return { numbers, maxPagePages, handleChangePagePages, currentPagePages };
};
export default PaginationController;
