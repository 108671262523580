interface GuestUserLayoutProps {
    children?: React.ReactNode;
}

// import LOGO_BIYECTIVA from '../../assets/images/LOGO_BIYECTIVA.png';
import Logo_PLENOENERGIA from '../../assets/images/logo-pleno-energia.png';

import styles from './GuestUserLayout.module.scss';
const GuestUserLayout = ({ children }: GuestUserLayoutProps) => {
    return (
        <>
            <div className={styles.header}>
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={Logo_PLENOENERGIA} />
                </div>
            </div>
            <div className={styles.layout}>{children}</div>
        </>
    );
};

export default GuestUserLayout;
