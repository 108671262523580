import PlantNavigationHeader from '@/components/StationNavigationHeader/StationNavigationHeader';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';

import styles from './StationData.module.scss';

import PowerGeneratedIcon from '../../assets/icons/PowerGeneratedIcon.png';
import PowerIcon from '../../assets/icons/PowerIcon.png';
import EuroIcon from '../../assets/icons/EuroIcon.png';
import CalendarIcon from '../../assets/icons/CalendarIcon.png';
import LocationIcon from '../../assets/icons/LocationIcon.png';
import MoneyIcon from '../../assets/icons/MoneyIcon.png';
import ProducedCapitalChart from '@/components/ProducedCapitalChart/ProducedCapitalChart';
import StationDataController from './StationData.controller';
import Map from '@/components/Map/Map';
import { StationDataMock } from './StationData.mock';

const StationData = () => {
    const width = window.innerWidth;
    const { stationData, parsedData } = StationDataController();

    if (stationData === undefined) {
        return null;
    }

    const {
        address,
        name,
        latitude,
        longitude,
        start_time,
        cost,
        total_accumulated_energy,
        total_capacity
    } = stationData;

    return (
        <div>
            <PlantNavigationHeader showBack subsectionText="DATOS" />
            <div className={styles.stationDataContainer}>
                <div
                    className={styles.stationDataItem}
                    style={{
                        height: 'fit-content'
                    }}
                >
                    <p className={styles.title}>Datos generales</p>
                    <div className={styles.stationGeneralDataContainer}>
                        <p className={styles.stationGeneralDataText}>
                            <span style={{ fontWeight: 'bold' }}>{name}</span>
                        </p>
                        <p className={styles.stationGeneralDataText}>
                            <img className={styles.stationGeneralDataIcon} src={LocationIcon} />
                            {address} ({latitude}, {longitude})
                        </p>

                        <Map center={{ lat: latitude, lng: longitude }} />
                        <p style={{ fontWeight: 'bold' }} className={styles.stationGeneralDataText}>
                            Puesta en marcha de la instalación
                        </p>
                        <p className={styles.stationGeneralDataText}>
                            <img className={styles.stationGeneralDataIcon} src={CalendarIcon} />
                            {start_time}
                        </p>
                        <p style={{ fontWeight: 'bold' }} className={styles.stationGeneralDataText}>
                            Coste total del proyecto
                        </p>
                        <p className={styles.stationGeneralDataText}>
                            <img className={styles.stationGeneralDataIcon} src={MoneyIcon} />
                            {cost}€
                        </p>
                    </div>
                </div>
                <div className={styles.stationDataLeftContainer}>
                    <div className={styles.stationDataLeftRowContainer}>
                        <div className={styles.stationDataItem}>
                            <p className={styles.title}>Energia total generada</p>
                            <img className={styles.icon} src={PowerGeneratedIcon} />
                            <p className={styles.bigNumber}>
                                <span
                                    style={{ fontSize: 60, color: '#009900', fontWeight: 'bold' }}
                                >
                                    {total_accumulated_energy.toLocaleString('de-DE')}
                                </span>
                                kWh
                            </p>
                        </div>
                        <div className={styles.stationDataItem}>
                            <p className={styles.title}>Potencia instalada</p>
                            <img className={styles.icon} src={PowerIcon} />
                            <p className={styles.bigNumber}>
                                <span
                                    style={{ fontSize: 60, color: '#009900', fontWeight: 'bold' }}
                                >
                                    {total_capacity.toLocaleString('de-DE')}
                                </span>
                                kW
                            </p>
                        </div>
                    </div>
                    {/* <div className={styles.stationDataItem}>
                        <p className={styles.title}>Información sobre la amortización</p>
                        <div className={styles.amortizedCapitalRow}>
                            <div className={styles.amortizedCapitalItem}>
                                <img className={styles.icon} src={EuroIcon} />
                                <p className={styles.bigNumber}>
                                    <span
                                        style={{
                                            fontSize: 60,
                                            color: '#EFB810',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {StationDataMock?.investment_payback.generated_income.toLocaleString(
                                            'de-DE'
                                        ) ?? 0}
                                    </span>
                                    €
                                </p>
                                <p className={styles.bigNumber}>Capital total generado</p>
                            </div>
                            <div className={styles.amortizedCapitalItem}>
                                <ResponsiveContainer width={300} height={300}>
                                    <PieChart>
                                        <Pie
                                            data={parsedData}
                                            dataKey="value"
                                            // adjust radius so that the pie fits perfectly in the container
                                            innerRadius={width > 600 ? 80 : 50}
                                            outerRadius={width > 600 ? 120 : 80}
                                            startAngle={180}
                                            endAngle={540}
                                        >
                                            <Label
                                                style={{
                                                    fontSize: '70px',
                                                    fill: 'var(--color-primary)'
                                                }}
                                                position="center"
                                                value={
                                                    StationDataMock?.investment_payback
                                                        .generated_income &&
                                                    StationDataMock?.investment_payback.cost
                                                        ? StationDataMock?.investment_payback
                                                              .generated_income /
                                                          StationDataMock?.investment_payback.cost
                                                        : '0%'
                                                }
                                            />
                                            {parsedData.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={
                                                        index === 0
                                                            ? 'var(--color-primary)'
                                                            : '#5c5c5b'
                                                    }
                                                />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className={styles.amortizedCapitalLegendRow}>
                                    <div className={styles.amortizedCapitalLegendChip} />
                                    <div className={styles.amortizedCapitalLegendText}>
                                        <span style={{ fontSize: '30px' }}>
                                            {StationDataMock?.investment_payback.generated_income ??
                                                0}
                                        </span>
                                        € generados
                                    </div>
                                </div>
                                <div className={styles.amortizedCapitalLegendRow}>
                                    <div
                                        className={styles.amortizedCapitalLegendChip}
                                        style={{ backgroundColor: '#5c5c5b' }}
                                    />

                                    <div className={styles.amortizedCapitalLegendText}>
                                        <span style={{ fontSize: '30px' }}>
                                            {StationDataMock?.investment_payback.cost &&
                                            StationDataMock?.investment_payback.generated_income
                                                ? StationDataMock.investment_payback.cost -
                                                  StationDataMock.investment_payback
                                                      .generated_income
                                                : 0}
                                        </span>
                                        € para recuperar inversión
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProducedCapitalChart />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default StationData;
