import {
    XAxis,
    ComposedChart,
    Tooltip,
    Area,
    ResponsiveContainer,
    Legend,
    YAxis,
    Line
} from 'recharts';
import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector';
import WastedEnergyChartController from './WastedEnergyChart.controller';

import styles from './WastedEnergyChart.module.scss';

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
function intersect(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    x3: number,
    y3: number,
    x4: number,
    y4: number
) {
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false;
    }

    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

    // Lines are parallel
    if (denominator === 0) {
        return false;
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1);
    let y = y1 + ua * (y2 - y1);

    const line1isHigher = y1 > y3;
    const line1isHigherNext = y2 > y4;

    return { x, y, line1isHigher, line1isHigherNext };
}

const WastedEnergyChart = () => {
    const { parsedData, timeRangeSelectorValue, onChangeTimeRangeSelectorValue } =
        WastedEnergyChartController();

    if (parsedData === undefined) {
        return <div className={styles.stationDetailsItem}></div>;
    }

    const getIntersectionColor = (_intersection: any, isLast = false) => {
        if (isLast) {
            return _intersection.line1isHigherNext ? 'red' : 'green';
        }

        return _intersection.line1isHigher ? 'red' : 'green';
    };

    // need to find intersections as points where we to change fill color
    const intersections = parsedData
        .map((d, i) =>
            intersect(
                i,
                d.wasted,
                i + 1,
                parsedData[i + 1]?.wasted,
                i,
                d.production,
                i + 1,
                parsedData[i + 1]?.production
            )
        )
        .filter((d) => d && !isNaN(d.x));

    // filtering out segments without intersections & duplicates (in case end current 2 segments are also
    // start of 2 next segments)
    const filteredIntersections = intersections.filter(
        (d, i) => i === intersections.length - 1 || d.x !== intersections[i - 1]?.x
    );

    return (
        <div className={styles.stationDetailsItem}>
            <div className={styles.stationDetailsItemTitleRow}>
                <div className={styles.stationDetailsItemTitle}>ENERGÍA DESPERDICIADA</div>
                <TimeRangeSelector
                    value={timeRangeSelectorValue}
                    onSelected={onChangeTimeRangeSelectorValue}
                />
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={parsedData}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }}
                >
                    <XAxis dataKey="key" scale="band" />
                    <YAxis />
                    <Tooltip
                        formatter={(value: any, name: string) => {
                            if (name === 'Producido') {
                                return [value + 'kWh', 'Producido'];
                            }

                            if (name === 'Consumido') {
                                return [value + 'kWh', 'Consumido'];
                            }

                            // range es una lista
                            const consumption_production_diff = value[0] - value[1];
                            if (consumption_production_diff > 0) {
                                // consumo > producción
                                return [
                                    consumption_production_diff.toFixed(2) + 'kWh',
                                    'Procedente de la red'
                                ];
                            }

                            return [-consumption_production_diff.toFixed(2) + 'kWh', 'Excedente'];
                        }}
                    />
                    <defs>
                        <linearGradient id="splitColor">
                            {filteredIntersections.length ? (
                                filteredIntersections.map((intersection, i) => {
                                    const nextIntersection = filteredIntersections[i + 1];

                                    let closeColor = '';
                                    let startColor = '';

                                    const isLast = i === filteredIntersections.length - 1;

                                    if (isLast) {
                                        closeColor = getIntersectionColor(intersection);
                                        startColor = getIntersectionColor(intersection, true);
                                    } else {
                                        closeColor = getIntersectionColor(intersection);
                                        startColor = getIntersectionColor(nextIntersection);
                                    }

                                    const offset =
                                        intersection.x /
                                        (parsedData.filter(
                                            (d) =>
                                                d.wasted !== undefined && d.production !== undefined
                                        ).length -
                                            1);

                                    return (
                                        <>
                                            <stop
                                                offset={offset}
                                                stopColor={closeColor}
                                                stopOpacity={0.9}
                                            />
                                            <stop
                                                offset={offset}
                                                stopColor={startColor}
                                                stopOpacity={0.9}
                                            />
                                        </>
                                    );
                                })
                            ) : (
                                <stop
                                    offset={0}
                                    stopColor={
                                        parsedData.length
                                            ? parsedData[0].wasted > parsedData[0].production
                                                ? 'red'
                                                : 'green'
                                            : 'green'
                                    }
                                />
                            )}
                        </linearGradient>
                    </defs>

                    <Area
                        name="Diferencia"
                        dataKey="range"
                        stroke="#8884d8"
                        strokeWidth={0}
                        fill={`url(#splitColor)`}
                        legendType="none"
                        activeDot={false}
                    />
                    <Line
                        type="linear"
                        dataKey="wasted"
                        stroke="#fe6b28"
                        strokeWidth={5}
                        name="Consumido"
                        dot={false}
                    />
                    <Line
                        type="linear"
                        dataKey="production"
                        stroke="#333c87"
                        strokeWidth={5}
                        name="Producido"
                        dot={false}
                    />
                    <Legend />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default WastedEnergyChart;
