import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';

import { getStationDataByIdThunk, setSelectedStationId } from '@/store/stations/stations.slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StationDataMock } from './StationData.mock';

function StationDataController() {
    const dispatch = useAppDispatch();

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const stationData = useSelector((state: RootState) => state.stations.stationData);
    const stationDataMock = StationDataMock;

    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));

    const fetchStationData = async (id: string) => {
        setSelectedStationAction(id);
        if (id) {
            dispatch(getStationDataByIdThunk({ station_id: id }));
        }
    };

    const { stationId } = useParams();

    useEffect(() => {
        if (stationId) {
            fetchStationData(stationId);
        }
    }, [stationId]);

    const parsedData = [
        {
            name: 'Generado',
            value: stationDataMock?.investment_payback.generated_income ?? 0
        },
        {
            name: 'Pendiente',
            value:
                StationDataMock?.investment_payback.cost &&
                stationDataMock?.investment_payback.generated_income
                    ? stationDataMock.investment_payback.cost -
                      stationDataMock.investment_payback.generated_income
                    : 100
        }
    ];

    return {
        parsedData,
        fetchStationData,
        setSelectedStationAction,
        selectedStationId,
        stationData
    };
}

export default StationDataController;
