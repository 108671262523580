export const StationResumeIS: any = {
    total_capacity: 0,
    month_accumulated_energy: 0,
    total_accumulated_energy: 0,
    environmental: {
        reduction_total_tree: 0,
        reduction_total_co2: 0,
        car_km_equivalent: 0
    },
    weather: {
        today: {
            status: '',
            performance: 0
        },
        tomorrow: {
            status: '',
            performance: 0
        },
        after_tomorrow: {
            status: '',
            performance: 0
        }
    },
    economic_impact: {
        total_generated: 0,
        amortized_investment: 0
    }
};
