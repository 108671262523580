import PlantNavigationHeader from "@/components/StationNavigationHeader/StationNavigationHeader"
import { StationDevice } from "@/models/StationDevice"
import { Link } from "react-router-dom"

import StationDevicesController from "./StationDevices.controller"
import styles from './StationDevices.module.scss'

const StationDevices = () => {

    const { stationDevices, stationId } = StationDevicesController()


    return (
        <div>
            <PlantNavigationHeader showBack subsectionText='SELECCIÓN DE INVERSOR' />
            <div className={styles.mainContainer}>
                <div className={styles.title}>LISTA DE INVERSORES</div>
                <div className={styles.invertersHeaderContainer}>
                    <div className={styles.headerCell}>
                        NOMBRE DEL INVERSOR
                    </div>
                    <div className={styles.headerCell}>
                        ESTADO
                    </div>
                    <div className={styles.headerCell}>
                        PRODUCIDO HOY (kWh)
                    </div>
                    <div className={styles.headerCell}>
                        POTENCIA ACTIVA (kW)
                    </div>
                    <div className={styles.headerCell}>
                        PRODUCCIÓN TOTAL (kWh)
                    </div>
                    <div className={styles.headerCell}>
                        POTENCIA NOMINAL (kW)
                    </div>
                </div>
                {stationDevices?.map((device: StationDevice) => (
                    <Link
                        className={styles.bodyRow}
                        to={`/${stationId}/device/${device.id}/`}
                    >
                        <div className={styles.bodyCell}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {device.name}
                        </div>
                        <div className={styles.bodyCell}
                            style={device.status === 'active' ? { color: '#00b300' } : { color: '#ff0000' }}
                        >
                            {device.status === 'active' ? 'Conectado' : 'Apagado'}
                        </div>
                        <div className={styles.bodyCell}>
                            {device.today_accumulated_energy}
                        </div>
                        <div className={styles.bodyCell}>
                            {device.reactive_power}
                        </div>
                        <div className={styles.bodyCell}>
                            {device.total_accumulated_energy}
                        </div>
                        <div className={styles.bodyCell}>
                            {device.rated_power}
                        </div>
                    </Link>
                ))}
            </div>
        </div >

    );
}

export default StationDevices