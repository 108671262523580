export enum ButtonVariants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERCIARY = 'terciary',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    PRIMARY_OUTLINE = 'primaryOutLine',
    SECONDARY_OUTLINE = 'secondaryOutLine',
    TERCIARY_OUTLINE = 'terciaryOutLine',
    SUCCESS_OUTLINE = 'successOutLine',
    DANGER_OUTLINE = 'dangerOutLine',
    WARNING_OUTLINE = 'warningOutLine'
}
export enum ButtonSizes {
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    ADAPTABLE = 'adaptable'
}
export interface ButtonProps {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    variant: ButtonVariants;
    size: ButtonSizes;
}
