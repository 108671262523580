import { MapContainer, TileLayer } from 'react-leaflet';

const containerStyle = {
    height: '35vh',
    width: '-webkit-fill-available',
    border: '1px solid #fe6b03',
    borderRadius: '10px'
};

interface MapProps {
    center: {
        lat: number;
        lng: number;
    };
}

const Map = ({ center }: MapProps) => {
    const { lat, lng } = center;
    return (
        <MapContainer
            style={containerStyle}
            center={[lat ?? 0, lng ?? 0]}
            zoom={13}
            scrollWheelZoom={false}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </MapContainer>
    );
};

export default Map;
