import classNames from 'classnames';
import { ButtonProps } from './Button.types';
import buttonStyles from './Button.module.scss';

function Button({ children, onClick, disabled, type = 'button', variant, size }: ButtonProps) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames(
                buttonStyles.button,
                buttonStyles[`button__${variant}`],
                buttonStyles[`button__${size}`],
                disabled ? buttonStyles['button__disabled'] : ''
            )}
            // eslint-disable-next-line react/button-has-type
            type={type}
        >
            {children}
        </button>
    );
}

export default Button;
