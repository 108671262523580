import React from 'react';
import App from './App';
import './styles/index.scss';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store/rootReducer';

const store = configureStore({ reducer: rootReducer });
const container = document.getElementById('app');
const root = createRoot(container!);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <div style={{ fontFamily: 'SegoeUI' }}>
                <App  />
            </div>
        </Provider>
    </React.StrictMode>
);
