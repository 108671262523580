import { useAppDispatch } from "@/index";
import { RootState } from "@/store/rootReducer";
import { getStationDeviceByIdThunk, setSelectedStationId } from "@/store/stations/stations.slice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


function StationDeviceController() {
    const dispatch = useAppDispatch()
    const setSelectedStationAction = (selectedStationId?: string) => dispatch(setSelectedStationId(selectedStationId))
    const stationDeviceData = useSelector((state: RootState) => state.stations.stationDeviceData);
    const { stationId, deviceId } = useParams()

    const fetchStationDevice = async (id: string, deviceId: string) => {
        setSelectedStationAction(id)
        dispatch(getStationDeviceByIdThunk({ station_id: id, device_id: deviceId }))
    }

    useEffect(() => {
        if (stationId && deviceId) {
            fetchStationDevice(stationId, deviceId)
        }
    }, [stationId, deviceId])


    return {
        stationDeviceData
    }
}

export default StationDeviceController;
