import PlantNavigationHeader from "@/components/StationNavigationHeader/StationNavigationHeader"

import OnIcon from '../../assets/icons/OnIcon.png'
import OffIcon from '../../assets/icons/OffIcon.png'

import EditIcon from '../../assets/icons/EditIcon.png'

import styles from './StationAlarms.module.scss'
import { Link } from "react-router-dom"
import StationAlarmsController from "./StationAlarms.controller"


const DAY_LABELS = [
    {
        label: 'Lunes',
        value: 'l',
    }, {
        label: 'Martes',
        value: 'm',
    }, {
        label: 'Miércoles',
        value: 'x',
    }, {
        label: 'Jueves',
        value: 'j',
    }, {
        label: 'Viernes',
        value: 'v',
    }, {
        label: 'Sábado',
        value: 's',
    }, {
        label: 'Domingo',
        value: 'd',
    }
]


const StationAlarms = () => {
    const { stationAlarms, stationAlarmsHistory, stationId, onChangedAlarmEnabled } = StationAlarmsController()


    if (!stationAlarms) {
        return (
            <div>
                <PlantNavigationHeader showBack subsectionText='ALARMAS' />
            </div>
        )
    }


    return (
        <div>
            <PlantNavigationHeader showBack subsectionText='ALARMAS' />
            <div className={styles.mainContainer}>
                <div className={styles.titleContainer}>
                    <p className={styles.title}>Gestor de alarmas</p>
                    <Link to={`/${stationId}/alarms/create`} className={styles.newAlarmButton}>
                        Nueva alarma
                    </Link>
                </div>
                <div className={styles.table}>
                    <div className={styles.tableHeaderRow}>
                        <div className={styles.headerCell}>String</div>
                        <div className={styles.headerCell}>Valor mínimo (V)</div>
                        <div className={styles.headerCell}>Valor máximo (V)</div>
                        <div className={styles.headerCell}>Días</div>
                        <div className={styles.headerCell}>Hora</div>
                        <div className={styles.headerCell}>Activar / desactivar alarma</div>
                        <div style={{ marginRight: 0 }} className={styles.headerCell}>Editar</div>
                    </div>
                    {stationAlarms?.map((item, index) => {
                        const daysLabel = item?.days?.map((day: string) => {
                            return DAY_LABELS.find(dayLabel => dayLabel.value === day)?.label
                        }).join(', ')

                        return (
                            <div
                                className={styles.tableBodyRow}
                                style={index % 2 === 0 ? {} : { backgroundColor: '#fefce7' }}
                            >
                                <div className={styles.bodyCell}>{item.string}</div>
                                <div className={styles.bodyCell}>{item.min_value}</div>
                                <div className={styles.bodyCell}>{item.max_value}</div>
                                <div className={styles.bodyCell}>{daysLabel}</div>
                                <div className={styles.bodyCell}>{item.time}</div>
                                <div
                                    className={styles.updateCell}
                                    onClick={() => { onChangedAlarmEnabled(item.id, item.enabled) }}>
                                    <img
                                        className={styles.onOffIcon}
                                        src={item.enabled ? OnIcon : OffIcon}
                                    />
                                </div>

                                <div className={styles.bodyCell}>
                                    <Link to={`/${stationId}/alarms/edit/${item.id}`}>
                                        <img className={styles.editIcon} src={EditIcon} />
                                    </Link>
                                </div>

                            </div>
                        )
                    })}
                </div>
                <p className={styles.title}>Histórico de alarmas</p>

                {stationAlarmsHistory && stationAlarmsHistory?.length > 0
                    ? <div className={styles.historicTable}>
                        <div className={styles.tableHeaderRow}>
                            <div className={styles.headerCell}>ID</div>
                            <div className={styles.headerCell}>NOMBRE ALARMA</div>
                            <div className={styles.headerCell}>FECHA</div>
                            <div className={styles.headerCell}>VALOR (V)</div>
                            <div style={{ marginRight: 0 }} className={styles.headerCell}>ESTADO</div>
                        </div>
                        {stationAlarmsHistory.map((item, index) => (
                            <div
                                className={styles.tableBodyRow}
                                style={index % 2 === 0 ? {} : { backgroundColor: '#fefce7' }}
                            >
                                <div className={styles.bodyCell}>{item.id}</div>
                                <div className={styles.bodyCell}>{item.desc}</div>
                                <div className={styles.bodyCell}>{item.time}</div>
                                <div className={styles.bodyCell}>{item.value}</div>
                                <div className={styles.bodyCell}>{item.status}</div>
                            </div>
                        ))}
                    </div>
                    : <p className={styles.noStationAlarmsHistoryText}>
                        No hay alarmas
                    </p>

                }

            </div>

        </div>
    )
}

export default StationAlarms