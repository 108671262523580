import { combineReducers } from 'redux';
import { getUserStationSlice } from './getUserStation.slice';
import { getUserStationsSlice } from './getUserStations.slice';
import { patchUserStationSlice } from './patchUserStation.slice';

export const UserStationsReducer = combineReducers({
    getUserStations: getUserStationsSlice.reducer,
    getUserStation: getUserStationSlice.reducer,
    patchUserStation: patchUserStationSlice.reducer
});
export const UserStationsActions = {
    ...getUserStationSlice.actions,
    ...getUserStationsSlice.actions,
    ...patchUserStationSlice.actions
};
