import { useAppDispatch } from '@/index';
import { TimeRangeOptions } from '@/models/TimeRangeOptions';
import { RootState } from '@/store/rootReducer';
import { getStationDetailByIdThunk } from '@/store/stations/stations.slice';
import { useState } from 'react';
import { useSelector } from 'react-redux';

type WastedEnergyChartChartData = {
    production: number;
    wasted: number;
    key: string;
    range: number[];
};

function WastedEnergyChartController() {
    const dispatch = useAppDispatch();

    const data = useSelector((state: RootState) => state.stations.stationDetail?.wasted_energy);
    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);

    const [timeRangeSelectorValue, setTimeRangeSelectorValue] = useState<TimeRangeOptions>('week');

    const onChangeTimeRangeSelectorValue = (value: TimeRangeOptions) => {
        setTimeRangeSelectorValue(value);
        if (selectedStationId) {
            dispatch(
                getStationDetailByIdThunk({
                    station_id: selectedStationId,
                    time_range: value,
                    data_type: 'wasted_energy'
                })
            );
        }
    };

    const parsedData: WastedEnergyChartChartData[] | undefined = data
        ? data.consumption.map((item, index) => ({
              wasted: item.value,
              key: item.key,
              production: data.production[index].value,
              range:
                  item.value !== undefined && data.production[index].value !== undefined
                      ? [item.value, data.production[index].value]
                      : []
          }))
        : undefined;

    return {
        parsedData,
        timeRangeSelectorValue,
        onChangeTimeRangeSelectorValue
    };
}

export default WastedEnergyChartController;