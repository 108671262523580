import IconClear from '@/assets/icons/time-clear.png';
import IconCloudy from '@/assets/icons/time-cloudy.png';
import IconDrizzle from '@/assets/icons/time-drizzle.png';
import IconMist from '@/assets/icons/time-mist.png';
import IconRain from '@/assets/icons/time-rain.png';
import IconSnow from '@/assets/icons/time-snow.png';
import IconThunderStorm from '@/assets/icons/time-thunderstorm.png';

interface TimeIconProps {
    status: 'thunderstorm' | 'drizzle' | 'rain' | 'snow' | 'mist' | 'clear' | 'cloudy' | 'unknown';
    className?: string;
}
const TimeIcon = ({ status, ...props }: TimeIconProps) => {
    const types = ['thunderstorm', 'drizzle', 'rain', 'snow', 'mist', 'clear', 'cloudy', 'unknown'];
    const elements = types.filter((e) => e.includes(status));

    const handleIcon: any = {
        thunderstorm: IconThunderStorm,
        drizzle: IconDrizzle,
        rain: IconRain,
        snow: IconSnow,
        mist: IconMist,
        clear: IconClear,
        cloudy: IconCloudy,
        unknown: IconClear
    };

    return <img src={handleIcon[elements[0] ?? '']} {...props} />;
};

export default TimeIcon;
