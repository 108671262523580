import styles from './Checkbox.module.scss';

interface CheckBoxProps {
    checked: boolean;
    onClick?: () => void;
}

const CheckBox = ({ checked, ...props }: CheckBoxProps) => (
    <div className={checked ? styles.checkedCheckbox : styles.checkbox} {...props}></div>
);

export default CheckBox;
