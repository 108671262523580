import {
    ComposedChart,
    Line,
    Bar,
    YAxis,
    XAxis,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import Spinner from '../Spinner/Spinner';
import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector';
import ProductedConsumedEnergyChartController from './ProductedConsumedEnergyChart.controller';

import styles from './ProductedConsumedEnergyChart.module.scss';

const ProductedConsumedEnergyChart = () => {
    const { parsedData, timeRangeSelectorValue, onChangeTimeRangeSelectorValue } =
        ProductedConsumedEnergyChartController();

    if (parsedData === undefined) {
        return (
            <div
                className={styles.stationDetailsItem}
                style={{
                    justifyContent: 'center'
                }}
            >
                <Spinner isLoading={true} />
            </div>
        );
    }

    return (
        <div className={styles.stationDetailsItem}>
            <div className={styles.stationDetailsItemTitleRow}>
                <div className={styles.stationDetailsItemTitle}>
                    Tu producción vs cuánto consumes
                </div>
                <TimeRangeSelector
                    value={timeRangeSelectorValue}
                    onSelected={onChangeTimeRangeSelectorValue}
                />
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={parsedData}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }}
                >
                    <XAxis dataKey="key" scale="band" />
                    <YAxis
                        yAxisId="consumption"
                        type="number"
                        stroke={'#e66c23'}
                        allowDataOverflow={true}
                    />
                    <YAxis
                        yAxisId="production"
                        type="number"
                        stroke="#333c87"
                        allowDataOverflow={true}
                        orientation="right"
                    />
                    <Tooltip />
                    <Legend verticalAlign="top" align="left" wrapperStyle={{ paddingBottom: 20 }} />
                    <Bar
                        dataKey="production"
                        barSize={70}
                        fill="#333c87"
                        name="Producido"
                        yAxisId="production"
                    />
                    <Line
                        type="monotone"
                        dataKey="consumption"
                        stroke="#e66c23"
                        strokeWidth={5}
                        name="Consumido"
                        yAxisId="consumption"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};
export default ProductedConsumedEnergyChart;
