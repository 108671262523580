import { Link } from 'react-router-dom';
import styles from './StationNavigationHeader.module.scss';
import LocationIcon from '../../assets/icons/LocationIcon.png';
import StationNavigationHeaderController from './StationNavigationHeader.controller';

interface StationNavigationHeaderProps {
    showBack?: boolean;
    subsectionText?: string;
    onBack?: () => void;
    isMain?: boolean;
}

const StationNavigationHeader = ({
    showBack,
    subsectionText,
    onBack,
    isMain
}: StationNavigationHeaderProps) => {
    const { stationData, selectedStationId } = StationNavigationHeaderController();
    return (
        <div className={styles.headerContainer}>
            <div>
                <div className={styles.name}>
                    {stationData?.name}
                    {subsectionText && <span> / {subsectionText}</span>}
                </div>
                <div className={styles.street}>
                    <img className={styles.locationIcon} src={LocationIcon} />
                    {stationData?.address}
                </div>
            </div>
            {showBack ? (
                <div className={styles.navContainer}>
                    <Link
                        to={`/${isMain ? '' : selectedStationId}`}
                        className={styles.navItem}
                        onClick={(e) => {
                            if (onBack) {
                                e.preventDefault();
                                onBack();
                            }
                        }}
                    >
                        VOLVER
                    </Link>
                </div>
            ) : (
                <div className={styles.navContainer}>
                    <Link to={`/${selectedStationId}/data`} className={styles.navItem}>
                        DATOS
                    </Link>
                    {/* <Link to={`/${selectedStationId}/documentation`} className={styles.navItem}>
                        DOCUMENTACIÓN
                    </Link> */}
                    <Link to={`/${selectedStationId}/devices`} className={styles.navItem}>
                        DESCRIPCIÓN
                    </Link>
                    <Link to={`/${selectedStationId}/edit-station`} className={styles.navItem}>
                        ADMINISTRACIÓN
                    </Link>
                    {/* <Link to={`/${selectedStationId}/alarms`} className={styles.navItem}>
                        ALARMAS
                    </Link> */}
                </div>
            )}
        </div>
    );
};
export default StationNavigationHeader;
