import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';

import { getStationsThunk, setSelectedStationId } from '@/store/stations/stations.slice';
import { generateFilter } from '@/utils/helpers/filter.helper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function HomeController() {
    const dispatch = useAppDispatch();
    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));
    const [searchValue, setSearchValue] = useState<string>('');

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const { stations, current_page, max_pages } = useSelector(
        (state: RootState) => state.stations.stationList
    );
    const handleChangePage = (page: number) => {
        dispatch(getStationsThunk(generateFilter({ limit: 10, page, name: searchValue })));
    };

    const handleSearchText = () => {
        dispatch(getStationsThunk(generateFilter({ limit: 10, page: 1, name: searchValue })));
    };

    useEffect(() => {
        dispatch(getStationsThunk(generateFilter({ limit: 10, page: 1, name: searchValue })));
    }, []);

    return {
        setSelectedStationAction,
        selectedStationId,
        stations,
        current_page,
        max_pages,
        handleChangePage,
        dispatch,
        searchValue,
        setSearchValue,
        handleSearchText
    };
}

export default HomeController;
