export const StationDataMock = {
    address: 'address 4',
    cost: 5000.5,
    investment_payback: {
        cost: 25,
        generated_income: 7,
        income_history: [
            { key: '10', value: 1 },
            { key: '20', value: 2 },
            { key: '30', value: 3 },
            { key: '40', value: 4 },
            { key: '50', value: 5 }
        ]
    },
    latitude: 37.60512,
    longitude: -0.98623,
    maintenance_type: 'GOLD',
    name: 'station 4',
    start_time: 'Sat, 01 Jan 2022 00:00:00 GMT',
    total_accumulated_energy: 8700.0,
    total_capacity: 100.0
};
