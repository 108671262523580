import { useAppDispatch } from "@/index";
import { RootState } from "@/store/rootReducer";
import { getStationDevicesByIdThunk, setSelectedStationId } from "@/store/stations/stations.slice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


function StationDevicesController() {
    const dispatch = useAppDispatch()
    const setSelectedStationAction = (selectedStationId?: string) => dispatch(setSelectedStationId(selectedStationId))
    const stationDevices = useSelector((state: RootState) => state.stations.stationDevices);
    const { stationId } = useParams()

    const fetchStationDevices = async (id: string) => {
        setSelectedStationAction(id)
        dispatch(getStationDevicesByIdThunk({ station_id: id }))
    }

    useEffect(() => {
        
        if (stationId) {
            fetchStationDevices(stationId)
        }
    }, [stationId])

  
    return {
        stationDevices,
        stationId
    }
}

export default StationDevicesController;
