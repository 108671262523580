import { useAppDispatch } from '@/index';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getStationByIdEditThunk, updateStationByIdThunk } from '@/store/stations/stations.slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import { StationData } from '@/models/StationData';
import { PricingType } from '@/store/userStations/getUserStations.slice';

interface StationFormik {
    id?: string;
    client: string;
    pricing_type: { value: string; label: string };
    tariff: { value: string; label: string };
    start_time: string;
    tiers: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
}

const EditStationController = () => {
    const params = useParams();
    const appDispatch = useAppDispatch();
    const station = useSelector((state: RootState) => state.stations.stationData);
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [dataTariff, setDataTariff] = useState<any>({
        id: '',
        client: '',
        pricing_type: 'pool',
        tariff: '2.0TD',
        start_time: '',
        tiers: {
            P1: '0',
            P2: '0',
            P3: '0'
        }
    });

    const [initialValues, setInitialValues] = useState({
        name: '',
        address: '',
        cost: 0,
        start_time: '',
        latitude: 0,
        longitude: 0
    });

    const generateStructure = (): StationFormik => {
        //@ts-ignore
        const aux: any = {
            id: '',
            client: '',
            pricing_type: 'pool',
            tariff: '2.0TD',
            start_time: '',
            tiers: {
                P1: '0',
                P2: '0',
                P3: '0'
            }
        };

        const item: any = station?.tariff_data ?? aux;
        //TODO conectar con el api de manera correcta
        const b: StationFormik = {
            // id: item.id,
            client: item?.client ?? '',
            pricing_type: {
                value: item?.pricing_type, //@ts-ignore
                label: PricingType[item?.pricing_type]
            },
            tariff: { value: item?.tariff, label: item?.tariff },
            start_time: item?.start_time ? item?.start_time : '',
            tiers: {
                ...item?.tiers
            }
        };

        return b;
    };

    const handleSaveTariff = (values: any) => {
        const dataValues = {
            pricing_type: values.pricing_type.value,
            tariff: values.tariff.value,
            tiers: { ...values.tiers },
            start_time: values.start_time
        };

        setDataTariff(dataValues);
        setOpenModal(false);
    };

    const handleOnSubmit = (values: any, actions: any) => {
        const dataSubmit = {
            station_id: params.id ?? '',
            station: { ...(values as StationData) }
            // tariff_data: { ...dataTariff } }
        };
        appDispatch(updateStationByIdThunk(dataSubmit));
        navigate('/');
    };

    useEffect(() => {
        if (station && params?.id) {
            setInitialValues({
                name: station.name ?? '',
                address: station.address ?? '',
                cost: station.cost ?? 0,
                start_time: new Date(station.start_time).toISOString().split('T')[0] ?? '',
                latitude: station.latitude ?? 0,
                longitude: station.longitude ?? 0
            });
        }
    }, [station]);
    useEffect(() => {
        if (params?.id) appDispatch(getStationByIdEditThunk({ station_id: params.id }));
    }, []);
    return {
        initialValues,
        handleOnSubmit,
        setOpenModal,
        openModal,
        handleSaveTariff,
        generateStructure,
        params
    };
};

export default EditStationController;
